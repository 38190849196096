import React from 'react';
import Carousel from 'react-grid-carousel';
import renderRichText from '../../utils/renderRichText';
import './styles.scss';

const Card = ({ content }) => {
  const articleContent = JSON.parse(content.articleContent.raw);

  return (
    <div className="column">
      <div className="card card-hover">
        <div
          className="card-content card-content-height"
          style={{ overflow: 'scroll' }}
        >
          <div className="content">{renderRichText(articleContent)}</div>
        </div>
      </div>
    </div>
  );
};

export const InTheNews = ({ allContentfulNewsArticle }) => {
  if (!allContentfulNewsArticle) return null;

  return (
    <div id="In the News" className="anchor-intro">
      <h2 className="is-size-2 has-text-centered has-text-weight-bold">
        In the News
      </h2>
      <Carousel cols={3} rows={2} gap={5} loop>
        {allContentfulNewsArticle.nodes.map((article, i) => {
          return (
            <Carousel.Item key={i}>
              <Card content={article} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
