import React from 'react';
import Carousel from 'react-grid-carousel';
import renderRichText from '../../utils/renderRichText';
import './styles.scss';

const Card = ({ content }) => {
  if (!content) return null;

  const articleContent = JSON.parse(content.content.raw);

  return (
    <div className="column">
      <div className="card card-hover">
        <div
          className="card-content card-content-height"
          style={{ overflow: 'scroll' }}
        >
          <div className="content">{renderRichText(articleContent)}</div>
        </div>
      </div>
    </div>
  );
};
export const SocialMediaSpotlight = ({ allContentfulSocialMediaSpotlight }) => {
  if (!allContentfulSocialMediaSpotlight) return null;

  return (
    <div id="Social Media Spotlight" className="anchor-intro">
      <h2 className="is-size-2 has-text-centered has-text-weight-bold">
        Social Media Spotlight
      </h2>
      <Carousel cols={3} rows={1} gap={5} loop>
        {allContentfulSocialMediaSpotlight.nodes.reverse().map((content, i) => {
          return (
            <Carousel.Item key={i}>
              <Card content={content} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
