import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';

const JobOpenings = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulJobOpenings {
        opening {
          raw
        }
      }
    }
  `);

  const { contentfulJobOpenings } = data;
  const jobOpeningsContent = JSON.parse(contentfulJobOpenings.opening.raw);

  return (
    <section className="section has-text-white anchor-intro" id="Job Openings">
      <div className="box">
        <h3 className="is-size-3 is-size-3-mobile has-text-weight-bold pb-2">
          Job Openings
        </h3>
        <div className="content">{renderRichText(jobOpeningsContent)}</div>
      </div>
    </section>
  );
};

export default JobOpenings;
