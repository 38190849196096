import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import renderRichText from '../../utils/renderRichText';
import 'react-multi-carousel/lib/styles.css';
import './styles.scss';

const Announcements = ({ title }) => {
  const data = useStaticQuery(graphql`
    {
      contentfulNews {
        title
        annoucements {
          raw
        }
      }
    }
  `);

  const { contentfulNews } = data;
  const announcementsContent = JSON.parse(contentfulNews.annoucements.raw);
  const contentfulTitle = contentfulNews.title;

  return (
    <section className="section has-text-white anchor-intro" id="Announcements">
      <div className="box">
        <h3 className="is-size-3 is-size-3-mobile has-text-weight-bold pb-2">
          {title || contentfulTitle}
        </h3>
        <div className="content">{renderRichText(announcementsContent)}</div>
      </div>
    </section>
  );
};

export default Announcements;
