import React from 'react';
import Layout from '../components/layout';
import Body from '../components/news/index';

const headerText = 'NEWS';

const News = () => {
  return (
    <Layout headerText={headerText} heroBg="grass">
      <Body />
    </Layout>
  );
};

export default News;
