import React from 'react';
import Announcements from './announcements';
import { useStaticQuery, graphql } from 'gatsby';
import './styles.scss';
import JobOpenings from './jobOpenings';
import { Publications } from './publications';
import { InTheNews } from './inTheNews';
import { SocialMediaSpotlight } from './socialMediaSpotlight';

const News = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulNewsArticle {
        nodes {
          articleContent {
            raw
          }
        }
      }
      allContentfulPub {
        nodes {
          articleContent {
            raw
          }
          title
        }
      }
      allContentfulSocialMediaSpotlight {
        nodes {
          content {
            raw
          }
          title
        }
      }
    }
  `);

  const {
    allContentfulNewsArticle,
    allContentfulPub,
    allContentfulSocialMediaSpotlight,
  } = data;

  return (
    <div>
      <InTheNews allContentfulNewsArticle={allContentfulNewsArticle} />
      <Publications allContentfulPub={allContentfulPub} />
      <SocialMediaSpotlight
        allContentfulSocialMediaSpotlight={allContentfulSocialMediaSpotlight}
      />
      <JobOpenings />
      <Announcements />
    </div>
  );
};

export default News;
